.cart {
    /* position: relative;
    width: 100%;
    display: flex;
    justify-content: center; */
    max-width: 1200px;
    min-height: calc(100vh - 116px);
}

.cart__container {
    display: flex;
    margin-bottom: 64px;
}

.cart__container>.blu-column {
    padding: 0 16px;
}


.payment-detail {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, .6);
}

.payment-detail>div {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-detail>div+div {
    margin-top: 4px;
}

.payment-detail>div {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-detail .tx-lime {
    color: #8bc63f;
}

.payment-detail__total {
    font-family: efframedium, Helvetica, Arial, sans-serif;
}

.applied-section {
    color: #8bc63f;
    font-family: effra, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 20px 20px 16px 16px;
    font-weight: 700;
}


.promo-box--has-applied {
    color: #0095da;
    background-color: #daf3ff;
}

.promo-box {
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: hsla(0, 0%, 52.2%, .5);
    border-radius: 16px;
    padding: 8px 16px;
    min-height: 58px;
    font-size: 16px;
    line-height: 21px;
    box-shadow: 0 0 24px -4px rgb(0 0 0 / 12%);
}

.cart__promo-box,
.cart__summary__detail {
    margin-bottom: 16px;
}

.blu-btn.b-full-width {
    width: 100%;
}

.blu-btn {
    position: relative;
    border-radius: 16px;
    font: 1.6rem/2rem "efframedium", Helvetica, Arial, sans-serif;
    text-decoration: none;
    transition: color .4s, background-color .4s, border-color .6s;
    white-space: nowrap;
    height: 4.8rem;
    padding: 0;
}

.blu-btn.b-primary {
    color: #fff;
    background-color: #f37021;
    border-color: #f37021;
}

.blu-btn.b-disabled {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: rgba(0, 0, 0, .38);
}

@media screen and (min-width: 1024px) {
    .blu-column.b-8-desktop {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 66.66667%;
    }
}

@media screen and (min-width: 1024px) {
    .blu-column.b-8-desktop {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 66.66667%;
    }
}

.shipping-info {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
}



.cart__shipping {
    margin-bottom: 16px;
}

/* *,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
} */

.box-group {
    margin-bottom: 16px;
    border-radius: 16px;
}

.box-group .box:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.item__content__info__attribute {
    font-size: 14px;
    color: rgba(0, 0, 0, .38);
}

.item__content__info__old-price--value {
    color: rgba(0, 0, 0, .38);
    text-decoration: line-through;
    margin-right: 4px;
}


.item__content__info__old-price--discount {
    border-radius: 8px;
    background-color: #ffdee0;
    color: #ef3b42;
    padding: 0 7px;
    font-family: efframedium, Helvetica, Arial, sans-serif;
}

.item__content__info__old-price {
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    line-height: 16px;
}

/* .ellipsis-oneline {
    white-space: nowrap;
} */

.cart-group__header__merchant-info {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
}

.ellipsis-oneline {
    white-space: nowrap;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
}


.cart-group__header__merchant-logo--seller {
    color: #858585;
}

.cart-group__header__merchant-logo {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 8px;
}

.cart-group__header__merchant-info {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
}

.cart-group__header__wrapper .merchant-section {
    display: -ms-flexbox;
    display: flex;
}

.item__content__info__name {
    line-height: 1.5;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* font-size: 16px;
    display: flex; */
}

.item__action {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
}


.box {
    border-radius: 8px;
    padding: 10px 16px;
    border: 1px solid #d6d6d6;
    margin-bottom: 15px;
    background-color: #fff;
}


.box-group.b-horizontal {
    display: -ms-flexbox;
    display: flex;
}

.item__action__icon-wrapper {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
}

.wishlist {
    position: relative;
}

.item__action--delete {
    cursor: pointer;
    margin-left: 16px;
}

.wishlist__icon__gray {
    color: #858585;
}

.item__action--delete>* {
    width: 24px;
    color: #858585;
}


.box-group.b-horizontal>.box,
.box-group.b-vertical>.box {
    border-radius: 0;
    margin-bottom: 0;
}

.box-group.b-horizontal>.box:not(:last-child) {
    border-right: 0;
}

.box-group.b-horizontal>.box,
.box-group.b-vertical>.box {
    border-radius: 0;
    margin-bottom: 0;
}

.box {
    border-radius: 8px;
    padding: 10px 16px;
    border: 1px solid #d6d6d6;
    margin-bottom: 15px;
    background-color: #fff;
}

.quantity-input .box {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 6px;
    border: none;
}

.quantity-input {
    font-size: 16px;
}

.box-group {
    border-radius: 8px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.quantity-input--value input {
    border: none;
    width: 50px;
    height: auto;
    padding: 0;
    text-align: center;
    margin-top: 5px;
}

.box-group.b-horizontal,
.box-group.b-vertical {
    margin-bottom: 15px;
}


.tx-black {
    color: rgba(0, 0, 0, 0.87);
}

a {
    color: #0095da;
    text-decoration: none;
    word-wrap: break-word;
    cursor: pointer;
}

/* .box-group .box {
    margin: 0;
    border-radius: 2px;
    padding: 16px;
} */

.cart-group__header--cnc,
.cart-group__header--no-error {
    font-size: 14px !important;
}

.box-group .box:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.box-group .box {
    margin: 0;
    border-radius: inherit;
    padding: 16px;
}


.box-group .box:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* .box-group .box {
    margin: 0px;
    border-radius: 2px;
    padding: 16px;
} */

.cart-group__header--cnc,
.cart-group__header--no-error {
    font-size: 14px !important;
}

.item__action {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
}

.quantity-input--btn {
    cursor: pointer;
    width: 24px;
    justify-content: center;
    font-size: 16px;
}

.quantity-input.box-group.b-horizontal {
    margin-bottom: 0;
}

.box-group.b-horizontal,
.box-group.b-vertical {
    margin-bottom: 15px;
}

.item__action__icon-wrapper {
    display: flex;
    margin-bottom: 16px;
}

.quantity-input.box-group.b-horizontal {
    margin-bottom: 0;

}

.box-group.b-horizontal {
    display: flex;
}

.box-group.b-horizontal,
.box-group.b-vertical {
    margin-bottom: 15px;
}

.quantity-input {
    font-size: 16px;
}

.box-group {
    border-radius: 8px;
}

.shipping-info__icon,
.shipping-info__info-icon,
.shipping-info__message {
    z-index: 1;
}

.shipping-info__icon {
    width: 32px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    transition: filter .2s ease;
}

.shipping-info__info-icon> {
    vertical-align: text-top;
    width: 24px;
    height: 24px;
}

.shipping-info__message {
    margin: auto 8px;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, .87);
    opacity: 1;
}

.item__action {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-left: auto;
}

a {
    color: #0095da;
    text-decoration: none;
    word-wrap: break-word;
    cursor: pointer;
}

.quantity-input {
    font-size: 16px;
}

.box-group {
    border-radius: 8px;
}

.item {
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-align: start; */
    align-items: flex-start;
}

.cart-group__header {
    font-size: 16px;
    border-bottom: 1px solid #f1f1f1;
}

.item__content {
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
    flex: 1;
    flex-wrap: wrap;
}

.box-group .box:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}



.cart-group__header--cnc,
.cart-group__header--no-error {
    font-size: 14px !important;
}

.item__content {
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
    flex: 1;
    flex-wrap: wrap;
}

.blu-column.b-8-desktop {
    flex: none;
    width: 66.66667%;
}

.blu-column.b-4-desktop {
    /* -ms-flex: none;
    -webkit-box-flex: 0; */
    flex: none;
    width: 33.33333%;
}

.sticky-group {
    position: sticky;
    top: 112px;
    z-index: 6;
}


.blu-column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.8rem;
}

.tab {
    margin: 12px 0 24px;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    color: rgba(0, 0, 0, .6);
    line-height: 20px;
    height: 30px;
}

.shadow {
    box-shadow: 0 0 24px -4px rgb(0 0 0 / 12%);
}

.cart__heading {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .6);
    font-size: 14px;
    line-height: 18px;
    display: -ms-flexbox;
    display: flex;
}

.cart__heading__icon {
    color: #858585;
}

.payment-detail .tx-primary {
    font-size: 16px;
    line-height: 24px;
    color: #f37021;
}

.box {
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
}

.cart__header {
    position: sticky;
    top: 90px;
    z-index: 5;
    padding: 14px 16px !important;
}


.quantity-input.box-group.b-horizontal {
    margin-bottom: 0;
    border: 0px;
}


.box-group.b-horizontal {
    display: flex;

}

.box-group.b-horizontal,
.box-group.b-vertical {
    margin-bottom: 15px;
}

.cart_empty {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.cart_empty>img {
    max-width: 50%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    /* display: block; */
}


/* @media (min-width: 768px) {
    .cart__filled {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .cart__filled {
        width: auto;
    }
} */

@media screen and (max-width:479px) {

    .cart_empty {
        margin-top: 50px;
    }

    .cart_empty>img {
        max-width: 100%;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
        /* display: block; */
    }

}


@media screen and (max-width:479px) {
    .cart__filled {
        width: auto;
    }
}

@media screen and (max-width: 600px) {
    .cart__container--right {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }

    .cart__header {
        visibility: hidden;
        display: none;

    }


    .blu-column.b-8-desktop {
        width: 100%;
        padding: 2px;

    }

    .group-cart-data {
        margin-top: 50px;
    }

    .item__content {
        flex-direction: column;
        margin-left: 8px;
        font-size: 14px;
        line-height: 18px;
    }

    .item__action__icon-wrapper {
        order: 2;
        margin-left: auto;
        margin-bottom: 0;
    }

    .item__action--delete {
        margin-left: 8px;
    }

    .quantity-input .box {
        height: 32px;
    }

    .item__image {

        width: 64px;
        height: 64px;
        margin-left: 4px;
    }

    .item__image>img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        display: block;
    }

    .item__checkbox-and-image-wrapper {
        display: flex;
        align-items: center;
    }


    .item__action {
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        margin-left: 0;
        width: 100%;
    }
}



/* .cart__header-section {
    position: sticky;
    z-index: 6;
    top: 0;
    border-radius: 0;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
} */

/* @media only screen and (min-width : 400px) {
    .cart__filled {
        left: 0;
        width: 50%;
        z-index: 2;
    }
}


@media only screen and (max-width : 600px) {
    .cart__filled {
        left: 0;
        width: 100%;
        z-index: 2;
    }
} */