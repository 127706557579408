.product-images-slider {
    .swiper-slide {
        padding-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
        }

    }

    .swiper-button-prev {
        left: 0;
        color: #000;
    }

    .swiper-button-next {
        right: 0;
        color: #000
    }
}

.product-images-slider-thumbs {
    .swiper-slide {
        cursor: pointer;
        border: 1px solid #dddbdd;

        &-thumb-active {
            border-color: #ff0000;
        }
    }

    &-wrapper {
        width: 80%;
        padding-top: 100%;
        overflow: hidden;
        position: relative;
        height: 50px;

        img {
            position: relative;
            top: 0;
            left: 0;
            width: 50%;
        }
    }


}