.content__item {
    margin-top: 8px
}

.blu-card {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 12%);
}

.address-card {
    position: relative;
}

.blu-card__content {
    padding: 20px;
}

.address-item {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.address-item__header {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.address-item__header .header__left {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    width: 95%;
    align-items: center;
}

.address-item__header span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.label.b-secondary {
    background-color: #daf3ff;
    color: #0095da;
}

.address-item__header .header__right {
    height: 24px;
    cursor: pointer;
}

.address-item__header .header__right svg {
    transform: scale(0.75);
}

.address-item__separator {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #eaeff3;
    margin: 12px 0;
}

.address-item__content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    margin-top: 25px;
    width: 100%;
}

.address-item__content .content__top-info {
    width: 100%;
}

.address-item__content .content__top-info .top-info__name {
    font-size: 16px;
    font-weight: bold;
    padding-right: 4px;
    color: rgba(0, 0, 0, 0.87);
    word-break: break-word;
}

.address-item__content .content__top-info .top-info__phone {
    border-left: 1px solid #e0e0e0;
    padding-left: 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.address-item__content .content__complete-address {
    line-height: 1.29;
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.87);
}

.address-item__footer {
    margin-top: 25px;
}


.address-item__footer .footer__action {
    font-size: 16px;
    font-weight: bold;
    margin-right: 12px;
    color: #0095da;
    cursor: pointer;
    border-right: 1px solid #eaeff3;
    padding-right: 12px;
    display: inline-block;
}

.address-item__content .content__geoLocationInfo {
    width: fit-content;
    margin-top: 10px;
}

.blu-ticker {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    position: relative;
    background-color: #f1f1f1;
    color: rgba(0, 0, 0, .6);
    padding: 8px;
    margin-bottom: 8px;
    z-index: 0;
}

.blu-ticker__msg {
    font: 1.2rem effra, Helvetica, Arial, sans-serif;
    z-index: 0;
    align-self: center;
}

.address-item__content .content__geoLocationInfo .ticker__content {
    display: flex;
    align-items: center;
}