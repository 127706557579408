// Modals


$white: #fff !default;
$gray-50: #f6f6f6 !default;
$gray-100: #babfc7 !default; // $gray-lightest
$gray-200: #ededed !default; // $gray-lighter
$gray-300: #dae1e7 !default; // $gray-light
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #b8c2cc !default; // $gray
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default; // $gray-dark
$gray-900: #2a2e30 !default;
$black: #22292f !default; // 231f48 22292f

$purple: #7367f0 !default; //$primary
$green: #28c76f !default; //$success
$blue: #00cfe8 !default; //$info
$orange: #ff9f43 !default; //$warning
$red: #ea5455 !default; //$danger

$primary: $purple  !default;
$secondary: #82868b !default;
$success: $green  !default;
$danger: $red  !default;
$info: $blue  !default;
$warning: $orange  !default;
$light: $gray-50  !default;
$dark: #4b4b4b !default;

$min-contrast-ratio: 3 !default;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px // Custom xxl size
    ) !default;

$container-max-widths: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px // Custom xxl size
    ) !default;
// scss-docs-end container-max-widths

//    Slide In Modal
$modal-slide-in-width-sm: 25rem !default; // (c)
$modal-slide-in-width: 28rem !default; // (c)
$modal-slide-in-width-lg: 30rem !default; // 

$border-width: 1px !default;
$border-color: #ebe9f1 !default;
$border-radius: 0.357rem !default;
$border-radius-lg: 0.6rem !default;
$border-radius-sm: 0.25rem !default;


$modal-inner-padding: 0.8rem 1.4rem !default;

$modal-content-border-width: 0 !default;
$modal-content-border-radius: $border-radius  !default;

$modal-footer-border-width: $border-width  !default;
$modal-footer-border-color: rgba($black, 0.05) !default;

$modal-header-padding-y: 0.8rem !default;
$modal-header-padding-x: 1.4rem !default;

$modal-sm: 400px !default;
$modal-xl: 94% !default;
$content-padding: 2rem !default;


// Modals
.modal {

    // Modal Header
    .modal-header {

        // background-color: $body-bg;
        // close button
        .btn-close {
            padding: 0.8rem;
            box-shadow: 0 5px 20px 0 rgba($black, 0.1
            );
        border-radius: $border-radius;
        background-color: $white;
        opacity: 1;
        transition: all 0.23s ease 0.1s;
        position: relative;
        transform: translate(18px, -10px);

        // For hover effect of close btn
        &:hover,
        &:focus,
        &:active {
            opacity: 1;
            outline: none;
            transform: translate(15px, -2px);
            box-shadow: none;
        }
    }
}

.modal-fullscreen {
    .modal-header {
        .btn-close {
            transform: translate(0, 0);
        }
    }
}

// Modal Content
.modal-content {
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: visible;
    box-shadow: 0 5px 20px 0 rgba($black, 0.1);
}

.modal-footer {
    padding: $modal-inner-padding;
}

// modal sticky for apps
&.modal-sticky {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    height: auto;
    position: fixed;

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .modal-dialog {
        margin-right: #{$content-padding} !important;
        box-shadow: 0px 0px 24px 0 rgba($black, 0.25);
        border-radius: $border-radius;
    }

    .modal-actions {
        background: transparent;
        box-shadow: none;
        line-height: 0;
    }
}
}

// Modal XS
.modal-xs {
    max-width: 300px;
}

// Modal XL
@media (min-width: 1200px) {
    .modal-xl {
        margin-left: 3%;
        margin-right: 3%;
    }
}

// Slide In Modal //
.modal-slide-in,
.modal-slide-in .modal {
    padding: 0 !important;
    overflow: hidden !important;
}

.modal-slide-in {
    .modal-dialog {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        margin: 0;
        max-width: none;
        width: ($modal-slide-in-width-sm - 5);

        .modal-content {
            // padding-bottom: ($modal-title-line-height * $btn-close-width) + $modal-header-padding-y;
            // padding-top: ($modal-title-line-height * $btn-close-width) + $modal-header-padding-y;
            overflow: auto;
            border-radius: 0;
            height: 100%;
        }

        .modal-body {
            padding-bottom: 0;
            padding-top: 0;
            margin: auto 0;
            flex-grow: 0;
        }

        @media (min-width: (map-get($grid-breakpoints, sm))) {
            width: $modal-slide-in-width;
        }

        &.sidebar-sm {
            width: $modal-slide-in-width-sm;
        }

        &.sidebar-lg {
            @media (min-width: (map-get($grid-breakpoints, sm))) {
                width: $modal-slide-in-width-lg;
            }
        }
    }

    .btn-close {
        top: calc($modal-header-padding-y / 2);
        z-index: 10;
        transform: none;
        position: absolute;
        top: 1.5rem;
        right: $modal-header-padding-x;
        margin: 0;
        padding: 0 !important;
        line-height: 0.5;
        transform: translate(0, -50%);
    }
}

// Slide from Right
.modal-slide-in.fade .modal-dialog,
.modal-slide-in .modal.fade .modal-dialog {
    transform: translateX(100%);
    transition: transform 0.1s ease-out;
}

.modal-slide-in.show .modal-dialog,
.modal-slide-in .modal.show .modal-dialog {
    transform: translateX(0) !important;
    transition: transform 0.1s ease-out;
}

// To remove Max-width of XS Modal in Small Screen
@media (max-width: 576px) {
    .modal {
        &:not(.modal-slide-in) {
            .modal-dialog:not(.modal-fullscreen) {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        .modal-xs,
        .modal-sm {
            max-width: unset;
        }

        &.modal-sticky .modal-dialog {
            margin-right: 0.2rem !important;
            margin-left: 0.2rem;
            margin-bottom: 3rem;
        }
    }
}

// ------------------------------------------------
// Modal Examples
// ------------------------------------------------
// --- refer and earn start ----- //
.modal-refer-earn {
    .modal-refer-earn-step {
        svg {
            height: 2.5rem;
            width: 2.5rem;
        }
    }
}

// --- refer and earn end ----- //
// --- share project start ----- //
// @include media-breakpoint-down(lg) {
//     #shareProject {
//         .list-group {
//             .dropdown-toggle {
//                 padding: 0.5rem;
//                 &::after {
//                     left: 0;
//                 }
//             }
//         }
//     }
// }

// --- share project end ----- //