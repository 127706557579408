.map-container .places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 200px;
}

.map-container {
    width: 100%;
    height: 50vh;
}

.combobox-input {
    width: 100%;
    padding: 0.5rem;
}