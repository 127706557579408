.container {
    max-width: 600px;
    margin: 0 auto;
}

.swiper_wrap {
    padding: 0px 50px;
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    text-align: left;
}

.swiper-button-next {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: -20px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
}

.swiper-button-prev {
    position: absolute;
    top: 50%;
    left: -100px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
    margin-top: 0px;
}