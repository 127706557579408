.dropdown-variants {
    width: 100%;
    /* margin: 0 auto; */
    position: relative;
    user-select: none;
}

.dropdown-variants .dropdown-variants-btn {
    padding: 15px 20px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.dropdown-variants .dropdown-variants-content {
    top: 110%;
    position: relative;
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    width: 100%;
}

.dropdown-variants .dropdown-variants-content .dropdown-variants-item {
    padding: 10px;
    cursor: pointer;

}


.dropdown-variants .dropdown-variants-content .dropdown-variants-item:hover {
    background: #f4f4f4;
}