.btn {
    margin: 0 auto;
    border: none;
    outline: none;
    padding: .5em 1em;
    /* font-size: 1em; */
    letter-spacing: 1px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
    text-transform: uppercase;
    border-radius: 3px;
    background-color: rgb(134, 49, 0);
    color: #fff;
}

.sidebar {
    width: 260px;
    flex-shrink: 0;
    height: 100%;
    overflow: auto;
}


.sidebar-item {
    padding: .5em 1em;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
}

.sidebar-title {
    display: flex;
    justify-content: space-between;
}

.sidebar-title span i {
    display: inline-block;
    width: 1.5em;
}

.sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform .3s;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
    transform: rotate(180deg);
}

.sidebar-content {
    padding-top: .25em;
    height: 0;
    overflow: hidden;
}

.sidebar-item.open>.sidebar-content {
    height: auto;
}

.sidebar-item.plain {
    padding: .5em 1em;
    text-decoration: none;
}

.sidebar-item.plain:hover {
    text-decoration: underline;
}

.sidebar-item.plain i {
    display: inline-block;
    width: 1.7em;
}