.form-control {
    outline: none;
    height: 50px;
    font-size: 14px;
    padding: 0 20px;
    background-color: transparent;
    border: 1px solid #dddddd;
    border-radius: 0;
    transition: all .4s ease;
    box-shadow: 0 0 0 #000;
    @include placeholder() {
        opacity: 1;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 #000;
        border-color: $color-1st;
    }
}

textarea.form-control {
    height: auto;
    padding: 2rem;
    resize: none;
}

.ps-input--has-icon {
    position: relative;

    > i {
        @include vertical-align();
        right: 15px;
    }
}

.ps-select {
    display: inline-block;
    min-width: 120px;
    cursor: pointer;

    .selectize-input {
        vertical-align: top;
    }
}

.ps-number {
    position: relative;

    span {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 50%;
        border-left: 1px solid #979797;
        transition: all .4s ease;

        &:before {
            font-family: FontAwesome;
            @include center();
            color: $color-heading;
        }

        &.up {
            border-bottom: 1px solid #979797;

            &:before {
                content: "\f106";
            }
        }

        &.down {
            top: auto;
            bottom: 0;

            &:before {
                content: "\f107";

            }
        }

        &:hover {
            cursor: pointer;
            background-color: $color-1st;

            &:before {
                color: #fff;

            }
        }
    }
}

.ps-checkbox {
    position: relative;
    display: block;

    > input {
        position: absolute;
        visibility: hidden;
        box-sizing: border-box;
    }

    label {
        margin-bottom: 0;
        position: relative;
        padding-left: 30px;
        font-family: $font-1st;
        color: rgba($color-heading, .5);
        font-weight: 400;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            height: 20px;
            width: 20px;
            z-index: 10;
            border: 1px solid $color-heading;
            transition: all .4s ease;

        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 7px;
            width: 6px;
            height: 10px;
            border: 2px solid #fff;
            border-top: none;
            border-left: none;
            z-index: 10;
            opacity: 0;
            @include transform(rotate(0deg));
            transition: all .4s ease;
        }
    }

    input[type=checkbox]:checked ~ label {
        &:before {
            background-color: $color-2nd;
            border-color: $color-2nd;
        }

        &:after {
            @include transform(rotate(45deg));
            opacity: 1;
        }
    }

    &--inline {
        display: inline-block;
        margin-bottom: 1rem;
    }

    &--color {
        margin-right: 1rem;

        > label {
            margin-bottom: 0;
            width: 20px;
            height: 20px;
            padding-left: 0;
            background-color: transparent;
            border-radius: 50%;
            transition: all .4s ease;

            &:before {
                width: 20px;
                height: 20px;
                @include center();
                border: none;
                border-radius: 50%;
            }

            &:after {
                display: none !important;
            }

        }

        input[type=radio]:checked ~ label {
            background-color: transparent;

            &:before {
                background-color: transparent;
                border: 1px solid #ccc;
            }
        }

        &.color-1 {
            @include checkbox-color(#000000);
        }

        &.color-2 {
            @include checkbox-color(#709abf);
        }

        &.color-3 {
            @include checkbox-color(#ad8111);
        }

        &.color-4 {
            @include checkbox-color(#57d6db);
        }

        &.color-5 {
            @include checkbox-color(#e5ac10);
        }

        &.color-6 {
            @include checkbox-color(#8ce84a);
        }

        &.color-7 {
            @include checkbox-color(#c9c9c9);
        }

        &.color-8 {
            @include checkbox-color(#e664ef);
        }

        &.color-8 {
            @include checkbox-color(#9d58e2);
        }

        &.color-8 {
            @include checkbox-color(#ffffff);
        }
    }

    &.color-sidebar {
        > label {
            &:before {
                width: 26px;
                height: 26px;
                border: 1px solid #ddd;
            }
        }
    }

    &--size {
        margin-right: 1rem;

        > label {
            margin-bottom: 0;
            padding: 8px 20px;
            border: none;
            color: #222222;
            background-color: #f6f6f6;
            @include border-radius(0);
            transition: all .4s ease;

            &:before, &:after {
                display: none;
            }
        }

        input[type=checkbox]:checked ~ label {
            color: #ffffff;
            background-color: #a7a7a7;
        }

    }
}

.ps-radio {
    position: relative;

    > input {
        position: absolute;
        visibility: hidden;
        box-sizing: border-box;
    }

    label {
        position: relative;
        margin-bottom: 0;
        padding-left: 30px;
        font-family: $font-1st;
        color: #737373;
        font-weight: 400;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            height: 20px;
            width: 20px;
            z-index: 10;
            border: 1px solid rgba(19, 8, 1, 0.2);
            background-color: #fff;
            transition: all .4s ease;
            border-radius: 50%;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            z-index: 15;
            opacity: 0;
            @include transform(scale(1.5, 1.5));
            @include transition(all .6s ease);
        }
    }

    input[type=radio]:checked ~ label {
        &:before {
            background-color: $color-2nd;
            border-color: $color-2nd;
        }

        &:after {
            @include transform(scale(1, 1));
            opacity: 1;

        }
    }

    &--inline {
        display: inline-block;
        margin-right: 20px;
    }

    &--color {
        margin-right: 1rem;

        > label {
            margin-bottom: 0;
            width: 20px;
            height: 20px;
            padding-left: 0;
            background-color: transparent;
            border-radius: 50%;
            transition: all .4s ease;

            &:before {
                width: 16px;
                height: 16px;
                @include center();
                border: none;
            }

            &:after {
                display: none !important;
            }

        }

        input[type=radio]:checked ~ label {
            background-color: rgba(#3dc47e, .5);
        }

        &.color-1 {
            @include radio-color(#35dccb);

        }

        &.color-2 {
            @include radio-color(#fec6df);
        }

        &.color-3 {
            @include radio-color(#ffd980);
        }
    }
}

.ps-color {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;

    &--1 {
        background-color: #000;
    }

    &--2 {
        background-color: #ad4d4b;
    }

    &--3 {
        background-color: #fdb201;
    }

    &:last-child {
        margin-right: 0;
    }
}

.tooltip {
    .tooltip-inner {
        font-size: 14px;
    }
}

.form-group {
    margin-bottom: 2.5rem;

    > label {
        margin-bottom: 1.5rem;
        font-weight: 400;
        color: #000000;
        line-height: 1em;
    }
}

.form-group--inline {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > label {
        margin-bottom: 0;
        font-weight: 700;
        min-width: 160px;
        max-width: 160px;
    }

    .form-group__content {
        width: 100%;
    }

    @include media("<lg") {
        padding-left: 0;
        label {
            position: static;
            @include transform(translateY(0));
        }
        &.textarea {
            label {
                @include transform(translateY(0));
            }
        }
    }
    @include media("<sm") {
        display: block;
    }
}

.form-group--number {
    display: inline-block;
    position: relative;

    button {
        background: none;
        border: none;
        background-color: transparent;
        @include vertical-align();
        width: 20px;
        height: 20px;
        max-width: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        color: $color-heading;
        font-size: 20px;
        line-height: 1em;

        &.up {
            right: 10px;
        }

        &.down {
            left: 10px;
        }
    }

    .form-control {
        border: 1px solid #ccc;
        height: 40px;
        padding: 0 25px;
        text-align: center;
        font-size: 14px;
        color: $color-heading;
        @include border-radius(50px);
        background-color: transparent;
        @include placeholder() {
            color: $color-heading;
        }
    }
}

.form-group--select {
    position: relative;

    i {
        @include vertical-align();
        right: 20px;
    }

    select {
        height: 40px;
        padding: 0 20px;
        @include appearance(none);
        background-color: transparent;
        border: 1px solid #ccc;
        @include border-radius(50px);
    }
}

.form-group--nest {
    display: flex;
    flex-flow: row nowrap;

    .ps-btn {
        border-radius: 0 4px 4px 0;
        padding-left: 25px;
        padding-right: 25px;
    }

    input {
        border-radius: 4px 0 0 4px;
        border-color: #ccc;
        border-right: none;
    }
}

.form-group--icon {
    position: relative;

    i {
        @include vertical-align();
        right: 10px;
        font-size: 16px;
    }

    .form-control {
        @include appearance(none);
    }

}

.ps-dropdown {
    position: relative;
    display: inline-block;

    > a {
        position: relative;
        display: block;
        padding-right: 20px;

        &:after {
            content: "\e93a";
            font: normal normal normal 12px/1 linearicons;
            @include vertical-align();
            right: 0;
        }
    }

    ul {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 100;
        min-width: 120px;
        max-width: 200px;
        width: 100%;
        padding: 4px 0;
        background-color: #fff;
        @include box-shadow(0 1px 1px 2px rgba(#ccc, .2));
        @include hidden;
        text-align: left;

        li {
            list-style-type: none;
            margin: 0;
            padding: 0;

            a {
                display: block;
                font-size: 1.3rem;
                color: $color-heading;
                padding: 6px 10px;
            }
        }
    }

    &:hover {
        ul {
            @include show;
        }
    }

    &.open {
        ul {
            @include show;
        }
    }
}

.ps-variant {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    border: 1px solid #999;

    &__tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        padding: 5px 10px;
        @include transform(translate(-50%, -50%));
        background-color: rgba(#000, .9);
        @include border-radius(2px);
        color: #ffffff;
        font-size: 12px;
        transition: all .4s ease;
        @include hidden;

        &:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            @include transform(translateX(-50%));
            @include triangle(12px, #000, down);
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &.ps-variant--size {
        position: relative;
        vertical-align: top;
        border-radius: 0;
        border-color: #d9d9d9;

        .ps-variant__size {
            @include center();
            color: #999;
            font-weight: 600;
        }

        &.active {
            .ps-variant__size {
                color: $color-heading;
            }
        }
    }

    &.ps-variant--color {
        width: 30px;
        height: 30px;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 50%;
            @include center();
        }

        &.color--1 {
            &:before {
                background-color: #000;
            }
        }

        &.color--2 {
            &:before {
                background-color: $color-gray;
            }
        }

        &.color--3 {
            &:before {
                background-color: #F06292;
            }
        }
    }

    &.ps-variant--image {
        width: auto;
        height: auto;
        max-width: 50px;
        border-radius: 0;
        border-color: #d9d9d9;

        img {
            position: relative;
        }
    }

    &:hover {
        .ps-variant__tooltip {
            @include show;
        }
    }

    &.active {
        &:before {
            border-color: #000;
        }

        .ps-variant__tooltip {
            @include show;
        }
    }
}

.select2 {
    display: block;

    .select2-selection--single {
        height: auto;
        outline: none;
        border-radius: 0;

        .select2-selection__rendered {
            line-height: 20px;
            padding: 10px 20px;
        }

        .select2-selection__arrow {
            @include vertical-align();
            right: 10px;
            width: 20px;
            height: 20px;

            &:before {
                content: '\f107';
                font-family: FontAwesome;
                @include center();
            }

            b {
                display: none;
            }
        }
    }

    .select2-selection--multiple {
        min-height: 0;
        border: none !important;

        .select2-selection__rendered {
            margin-bottom: 0;
            padding: 0;
            padding-left: 10px;
            vertical-align: top;

            input {
                margin-top: 0;
                height: 40px;
                padding: 0 10px;
            }

            .select2-selection__choice {
                line-height: 20px;
                margin-right: 10px;
                margin-top: 10px;
            }
        }
    }
}

.select2-container {
    .select2-dropdown {
        border-color: #ccc;
    }

    .select2-results {
        li {
            color: #93969a;
        }
    }
}

.slimScrollRail, .slimScrollBar {
    border-radius: 0 !important;
}

.slimScrollBar {
    opacity: 1 !important;
    background-color: #222 !important;
}

.ps-progress {
    position: relative;

    span {
        @include vertical-align();
        left: 0;
        height: 100%;
    }
}

